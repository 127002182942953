import React from 'react';

import styles from './styles.scss';

const OurPoliciesPage = () => {
  return (
    <div className={styles.OurPolicies}>
      <div className={styles.Title}>OUR POLICIES</div>
      <div className={styles.Subtitle}>Important Information To Know</div>
      <div className={styles.Content}>
        <div className={styles.Policy}>
          VIFAH must be contacted for claim as soon as possible when issues are found, FID#, photos, Order information
          must be provided. Then replacement/refund will be processed as requests.
        </div>
        <div className={styles.Policy}>
          <b>Full refund</b>: In any cases product show defects covered in warranty term and user does not prefer
          replacement of any kind, they may contact customer service department via email: customerservice@vifah.com.
        </div>
        <div className={styles.Policy}>
          <b>Partial refund</b>: In any cases that not covered by warranty terms, buyer may request to return the
          product without any defect yet he or she shall be charged for all the shipping fee and 30% restocking fee.
        </div>
        <div className={styles.Policy}>
          All VIFAH warranties are limited warranties and are limited to the original purchaser with proof of purchase.
        </div>
        <div className={styles.Policy}>
          In some cases, everyday wear and tear may depreciate for the time that the product was in use.
        </div>
        <div className={styles.Policy}>
          In no event shall VIFAH be liable for incidental or consequential damages resulting from the use of the
          product.
        </div>
        <div className={styles.Policy}>
          All shipments should be inspected immediately upon arrival. Please make note of any damage to boxes when
          signing for shipment.
        </div>
        <div className={styles.Policy}>Report any losses or damages promptly to VIFAH.</div>
        <div className={styles.Policy}>Notification must be made within one week of delivery.</div>
        <div className={styles.Policy}>Save damaged cartons until advised by the VIFAH or claim is resolved.</div>
        <div className={styles.Policy}>
          All returns MUST be authorized by VIFAH, which will advise you as to return authorization and arrange for
          pickup or repair of any damaged merchandise.
        </div>
      </div>
    </div>
  );
};

export default {
  component: OurPoliciesPage,
};
