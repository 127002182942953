// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Ye3qH2wB\\+\\+2JasuIj14XQQ\\=\\= {\n  width: 150px;\n}\n.Ye3qH2wB\\+\\+2JasuIj14XQQ\\=\\= .sypCuuMYT7i-aZwirZJL-A\\=\\= {\n  width: 100%;\n  height: 100%;\n  display: block;\n}\n\n@media (max-width: 676px) {\n  .Ye3qH2wB\\+\\+2JasuIj14XQQ\\=\\= {\n    width: 75px;\n  }\n  .Ye3qH2wB\\+\\+2JasuIj14XQQ\\=\\= .sypCuuMYT7i-aZwirZJL-A\\=\\= {\n    width: 100%;\n    height: 25px;\n    display: block;\n    margin-top: 27px;\n  }\n}", "",{"version":3,"sources":["webpack://./source/components/common/logo/styles.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;AACJ;AACI;EACI,WAAA;EACA,YAAA;EACA,cAAA;AACR;;AAGA;EACI;IACI,WAAA;EAAN;EAEM;IACI,WAAA;IACA,YAAA;IACA,cAAA;IACA,gBAAA;EAAV;AACF","sourcesContent":[".Logo { \n    width: 150px;\n\n    .LogoImage {\n        width: 100%;\n        height: 100%;\n        display: block;\n    }\n}\n\n@media(max-width: 676px) { \n    .Logo { \n        width: 75px;\n    \n        .LogoImage {\n            width: 100%;\n            height: 25px;\n            display: block;\n            margin-top: 27px;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Logo": "Ye3qH2wB++2JasuIj14XQQ==",
	"LogoImage": "sypCuuMYT7i-aZwirZJL-A=="
};
export default ___CSS_LOADER_EXPORT___;
