import moment from 'moment';

export const blogTransform = blog => ({
  blogId: blog['blog_id'],
  title: blog['title'],
  author: blog['author'],
  previewImage: blog['preview_image'],
  createdAt: moment(blog['created_at']).format('MMM Do, YYYY'),
  estimation: blog['estimation'],
  views: blog['views'],
  content: blog['content'],
});
