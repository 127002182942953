import React, { Fragment, Component } from 'react';
import axios from 'axios';
import { isEmpty } from 'lodash';

import { FREQUENTLY_ASK_QUESTIONS_API_ENDPOINT } from '@constants';
import styles from './styles.scss';

class FrequentlyAskQuestionsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      faqs: [],
    };
  }

  componentDidMount() {
    axios.get(FREQUENTLY_ASK_QUESTIONS_API_ENDPOINT).then(response => {
      this.setState({ faqs: response.data });
    });
  }

  render() {
    const { faqs } = this.state;
    if (!isEmpty(faqs)) {
      return (
        <div className={styles.FrequentlyAskQuestions}>
          <div className={styles.Header} styles={{ backgroundImage: 'url(/media/faq-header.jpg)' }}>
            <div className={styles.Title}>FAQ</div>
            <div className={styles.Subtitle}>Helpful Information</div>
          </div>
          <div className={styles.Body}>
            {faqs.map(faq => {
              return (
                <Fragment key={`FREQUENTLY_ASK_QUESTIONS_${faq.question}`}>
                  <div className={styles.Question}>{faq.question}</div>
                  <div className={styles.Answer}>{faq.answer}</div>
                </Fragment>
              );
            })}
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default {
  component: FrequentlyAskQuestionsPage,
};
