import React, { Component } from 'react';
import axios from 'axios';

import { WARRANTIES_API_ENDPOINT } from '@constants';
import styles from './styles.scss';

class RegisterYourProductPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      retailerPurchasedFrom: '',
      orderNumber: '',
      isSubmitted: false,
    };
    this.handleCustomerInformationChange = this.handleCustomerInformationChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleCustomerInformationChange(event) {
    this.setState({ [event.target.name]: event.target.value });

    if (this.state.isSubmitted) {
      this.setState({ isSubmitted: false });
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    const { firstName, lastName, email, phone, retailerPurchasedFrom, orderNumber } = this.state;
    const customerInformation = {
      'first_name': firstName,
      'last_name': lastName,
      'email': email,
      'phone': phone,
      'retailer_purchased_from': retailerPurchasedFrom,
      'order_number': orderNumber,
    };
    axios.post(WARRANTIES_API_ENDPOINT, { 'customer_information': customerInformation });
    document.getElementById('warranty-form').reset();
    this.setState({ isSubmitted: true });
  }

  renderSuccessMessage() {
    return (
      <div>
        <div className={styles.SuccessMessage}>
          Thank you for submitting your order information, your warranty is now activated.
        </div>
        <div className={styles.SuccessMessage}>
          { 'Please contact us at' } <a href='mailto:customerservice@vifah.com'>customerservice@vifah.com</a>
          {' if you need further assistance.'}
        </div>
      </div>
    );
  }

  render() {
    const { isSubmitted } = this.state;
    return (
      <div className={styles.RegisterYourProduct}>
        <div className={styles.Title}>REGISTER YOUR PRODUCT</div>
        <div className={styles.Subtitle}>Get Started Now</div>
        <div className={styles.Information}>
          Please provide the required information to have your product warranty activated
        </div>
        <form id='warranty-form' className={styles.FormWrapper} onSubmit={this.handleSubmit}>
          <div>
            <input
              type='text'
              name='firstName'
              placeholder='First Name*'
              className={styles.ShortInput}
              onChange={this.handleCustomerInformationChange}
              autoComplete='off'
              required
            />
            <input
              type='text'
              name='lastName'
              placeholder='Last Name*'
              className={styles.ShortInput}
              onChange={this.handleCustomerInformationChange}
              autoComplete='off'
              required
            />
          </div>
          <div>
            <input
              type='email'
              name='email'
              placeholder='Email*'
              className={styles.ShortInput}
              onChange={this.handleCustomerInformationChange}
              autoComplete='off'
              required
            />
            <input
              type='text'
              name='phone'
              placeholder='Phone'
              className={styles.ShortInput}
              onChange={this.handleCustomerInformationChange}
              autoComplete='off'
            />
          </div>
          <div>
            <input
              type='text'
              name='retailerPurchasedFrom'
              placeholder='Retailer Purchased From*'
              className={styles.LongInput}
              onChange={this.handleCustomerInformationChange}
              autoComplete='off'
              required
            />
          </div>
          <div>
            <input
              type='text'
              name='orderNumber'
              placeholder='Order Number* (see guides to find your order number in Help section)'
              className={styles.LongInput}
              onChange={this.handleCustomerInformationChange}
              autoComplete='off'
              required
            />
          </div>
          <div>
            <button className={styles.SubmitButton}>Submit</button>
          </div>
        </form>
        <div className={styles.SuccessMessageWrapper}>
          { isSubmitted ? this.renderSuccessMessage() : '\u00A0' }
        </div>
      </div>
    );
  }
}

export default {
  component: RegisterYourProductPage,
};
