import React from 'react';

import styles from './styles.scss';

const AcceptanceOfTermsOfUse = () => {
  return (
    <div className={styles.AcceptanceOfTermsOfUse}>
      <div className={styles.Title}>Acceptance of Terms of Use</div>
      <div className={styles.Summary}>
            By using, visiting, or browsing any web site or portal published by The Vifah Company,
            any of its affiliates or subsidiaries (collectively, "Vifah") (collectively, the "Web Site(s)") you accept,
            without limitation or qualification, these Terms of Use and agree, without limitation, to the terms of our
            Privacy Policy. If you do not agree to these Terms of Use and to our Privacy Policy, please do not use
            Acceptance Of Terms Of Use services or visit our Web Sites. Acceptance of these Terms of Use constitutes a
            legal agreement between you and Vifah.
      </div>

      <div className={styles.Title}>Intellectual Property</div>
      <div className={styles.Summary}>
            The elements of the Web Sites, including, but not limited to, text, graphics, logos, depictions, designs,
            photographs, images, digital downloads, data compilations, sound, video, and software are protected by
            United States and international copyright, trademark, and trade dress laws and are the property of
            Vifah or its affiliates. The compilation of the content on the Web Sites is the exclusive property of Vifah.
      </div>

      <div className={styles.Title}>Copyrights</div>
      <div className={styles.Summary}>
            The copyright in all materials provided on the Web Sites is owned by Vifah or its affiliate(s).
            Except as stated herein, none of the material contained in the Web Sites may be copied, reproduced,
            distributed, republished, downloaded, displayed, posted or transmitted in any form or by any means,
            including, but not limited to, electronic, mechanical, photocopying, recording or otherwise,
            without the prior written consent of Vifah. Permission is hereby granted to view, copy,
            print and download the materials on the Web Sites for personal, noncommercial use only,
            provided such materials are used solely for informational purposes, and all copies, or portions thereof,
            include this copyright notice. Vifah may revoke any of the foregoing rights at any time. You may not,
            without Vifah' prior written consent, "mirror" any material contained on the Web Sites on any other server.
            Upon termination of any rights extended hereunder, you must immediately destroy any downloaded and printed
            materials obtained from any Web Site. Any unauthorized use of any material contained on the Web Sites may
            violate copyright laws, trademark laws, the laws of privacy and publicity,
            and communications regulations and statutes.
      </div>

      <div className={styles.Title}>Notification of Copyright Infringement</div>
      <div className={styles.Summary}>
            If you believe that a work you own and that is protected by copyright has been used or
            copied in a way that constitutes copyright infringement and such infringement is
            occurring on the Web Sites or on sites linked to/from the Web Sites,
            please provide Vifah' Copyright Agent (identified below) a written or electronic notice ("Notice")
            containing the following information:
        <ul>
          <li>your full legal name, as well as your address, telephone number, and email address;</li>
          <li>
                    a physical or electronic signature of the person authorized to act on behalf of the owner of
                    the copyrighted work(s) that is/are alleged to have been infringed;
          </li>
          <li>an identification of the copyrighted work(s) you claim is/are being infringed;</li>
          <li>an identification of the material you claim is infringing and its location;</li>
          <li>
                    a statement by you that you have a good faith belief that the disputed
                    use of the copyrighted work(s) is/are not authorized by you, the copyright owner,
                    its agent, or the law; and
          </li>
          <li>
                    a statement by you, made under penalty of perjury, that the above information in your
                    Notice is accurate and that you are the copyright owner or authorized to act on the
                    copyright owner's behalf.
          </li>
        </ul>

        Copyright Agent:<br />
        General Counsel <br />
        Address: <br />
        Dropship Vendor Group LLC<br />
        21800 Opportunity Way<br />
        Riverside, California 92508<br />
        Phone: (425) 599-6134<br />
        email: liz@vifah.com<br />
      </div>
      <div className={styles.Summary}>
        THE COPYRIGHT AGENT SHOULD BE CONTACTED ONLY FOR THE PURPOSE OF NOTIFYING THE COPYRIGHT AGENT THAT YOUR
        WORK HAS BEEN USED OR COPIED IN A WAY THAT CONSTITUTES COPYRIGHT INFRINGEMENT AND SUCH INFRINGEMENT IS
        OCCURRING ON THE WEB SITES OR ON SITES LINKED TO/FROM THE WEB SITES.
        ANY REQUEST, INQUIRY OR COMMUNICATION THAT IS SENT TO THE COPYRIGHT AGENT FOR ANY OTHER PURPOSE WILL NOT
        BE RESPONDED TO.
      </div>

      <div className={styles.Title}>Trademarks</div>
      <div className={styles.Summary}>
        The trademarks, service marks and logos ("Trademarks") used and displayed on the Web Sites are registered
        or unregistered Trademarks of Vifah. Nothing on the Web Sites shall be construed as granting,
        by implication, estoppel or otherwise any license or right to use any Trademark displayed on the
        Web Sites without the prior written consent of the Trademark owner. The name of Vifah or any Trademark
        may not be used in any way including in any advertising or publicity pertaining to distribution of materials
        on the Web Sites without the prior written consent of Vifah. Vifah prohibits the use of any Vifah logo and
        Trademark as a "hot" link to any website unless establishment of such link is approved in advance by
        Vifah in writing.
      </div>
    </div>
  );
};

export default {
  component: AcceptanceOfTermsOfUse,
};
