import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.scss';

const NotFoundPage = ({ staticContext = {} }) => {
  staticContext.notFound = true;
  return (
    <div className={styles.PageNotFound}>
      <div className={styles.Container}>
        <div className={styles.Content}>
          <h1>Ooops!</h1>
        </div>
        <div className={styles.Desc}>The Page can't be found</div>
        <a className={styles.Link} href='/'>
          Go to Homepage
        </a>
      </div>
    </div>
  );
};

NotFoundPage.propTypes = {
  staticContext: PropTypes.object,
};

export default {
  component: NotFoundPage,
};
