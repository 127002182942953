// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".bMfwYCnikfzZ0\\+dI4ivdEQ\\=\\= ._6Oo41nx39ykz5\\+Tfnqim3Q\\=\\= {\n  display: inline-block;\n  padding: 25px 75px;\n}\n.bMfwYCnikfzZ0\\+dI4ivdEQ\\=\\= ._6Oo41nx39ykz5\\+Tfnqim3Q\\=\\= .s1Bj8J5sXXKSLBR5EvFJig\\=\\= {\n  width: 400px;\n  height: 400px;\n}\n.bMfwYCnikfzZ0\\+dI4ivdEQ\\=\\= ._6Oo41nx39ykz5\\+Tfnqim3Q\\=\\= .gz5m\\+\\+aTq3vmtOUU67LJKQ\\=\\= {\n  text-align: center;\n  font-size: 12px;\n  height: 50px;\n  width: 400px;\n  overflow: hidden;\n}", "",{"version":3,"sources":["webpack://./source/components/new-arrivals-page/styles.scss"],"names":[],"mappings":"AAEI;EACI,qBAAA;EACA,kBAAA;AADR;AAGQ;EACI,YAAA;EACA,aAAA;AADZ;AAIQ;EACI,kBAAA;EACA,eAAA;EACA,YAAA;EACA,YAAA;EACA,gBAAA;AAFZ","sourcesContent":[".NewArrivals {\n\n    .Product {\n        display: inline-block;\n        padding: 25px 75px;\n\n        .ProductImage {\n            width: 400px;\n            height: 400px;\n        }\n    \n        .ProductName {\n            text-align: center;\n            font-size: 12px;\n            height: 50px;\n            width: 400px;\n            overflow: hidden; \n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"NewArrivals": "bMfwYCnikfzZ0+dI4ivdEQ==",
	"Product": "_6Oo41nx39ykz5+Tfnqim3Q==",
	"ProductImage": "s1Bj8J5sXXKSLBR5EvFJig==",
	"ProductName": "gz5m++aTq3vmtOUU67LJKQ=="
};
export default ___CSS_LOADER_EXPORT___;
