// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fdgVv4j4SWTLhGBnK3oHRA\\=\\= {\n  margin: 100px 200px;\n  color: #3A2315;\n  font-size: 18px;\n  font-weight: 500;\n}\n.fdgVv4j4SWTLhGBnK3oHRA\\=\\= .zd7FAdx6WRVjRT33bE9KhQ\\=\\= {\n  width: 40px;\n  height: 20px;\n}\n.fdgVv4j4SWTLhGBnK3oHRA\\=\\= .uJHxcKyewN1YIdDn7YJo9g\\=\\= {\n  display: inline-block;\n  font-size: 18px;\n  font-weight: 500;\n  color: #767676;\n  position: relative;\n  left: 15px;\n  bottom: 5px;\n}\n.fdgVv4j4SWTLhGBnK3oHRA\\=\\= .bj2ASWrcjZTlrDG-KimzVw\\=\\= {\n  font-size: 32px;\n  font-weight: 600;\n  margin: 40px 0;\n  line-height: 1.2em;\n}", "",{"version":3,"sources":["webpack://./source/components/blog/styles.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;AACF;AACE;EACE,WAAA;EACA,YAAA;AACJ;AAEE;EACE,qBAAA;EACA,eAAA;EACA,gBAAA;EACA,cAAA;EACA,kBAAA;EACA,UAAA;EACA,WAAA;AAAJ;AAGE;EACE,eAAA;EACA,gBAAA;EACA,cAAA;EACA,kBAAA;AADJ","sourcesContent":[".Blog {\n  margin: 100px 200px;\n  color: #3A2315;\n  font-size: 18px;\n  font-weight: 500;\n\n  .LogoImage {\n    width: 40px;\n    height: 20px;\n  }\n\n  .Author {\n    display: inline-block;\n    font-size: 18px;\n    font-weight: 500;\n    color: #767676;\n    position: relative;\n    left: 15px;\n    bottom: 5px;\n  }\n\n  .Title {\n    font-size: 32px;\n    font-weight: 600;\n    margin: 40px 0;\n    line-height: 1.2em;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Blog": "fdgVv4j4SWTLhGBnK3oHRA==",
	"LogoImage": "zd7FAdx6WRVjRT33bE9KhQ==",
	"Author": "uJHxcKyewN1YIdDn7YJo9g==",
	"Title": "bj2ASWrcjZTlrDG-KimzVw=="
};
export default ___CSS_LOADER_EXPORT___;
