require('react');

import App from './App';
import HomePage from '@components/home-page';
import NotFoundPage from '@components/not-found-page';
import FrequentlyAskQuestionsPage from '@components/frequently-ask-questions-page';
import ProductsPage from '@components/products-page';
import NewArrivals from '@components/new-arrivals-page';
import OurPoliciesPage from '@components/our-policies-page';
import ReturnsAndReplacementsPage from '@components/returns-and-replacements-page';
import ShippingAndDeliveryPage from '@components/shipping-and-delivery-page';
import AboutUsPage from '@components/about-us-page';
import WarrantiesPage from '@components/warranties-page';
import ActivateYourWarrantyPage from '@components/activate-your-warranty-page';
import RegisterYourProductPage from '@components/register-your-product-page';
import ProductDetailPage from '@components/product-detail-page';
import TipsAndIdeas from '@components/tips-and-ideas-page';
import Blog from '@components/blog';
import AcceptanceOfTermsOfUse from '@components/acceptance-of-terms-of-use';

export default [
  {
    ...App,
    routes: [
      {
        ...HomePage,
        path: '/',
        exact: true,
      },
      {
        ...FrequentlyAskQuestionsPage,
        path: '/frequently-ask-questions/',
      },
      {
        ...ProductsPage,
        path: '/products/',
        exact: true,
      },
      {
        ...ProductDetailPage,
        path: '/products/:skuCode/',
      },
      {
        ...NewArrivals,
        path: '/new-arrivals/',
        exact: true,
      },
      {
        ...ProductDetailPage,
        path: '/new-arrivals/:skuCode',
      },
      {
        ...OurPoliciesPage,
        path: '/our-policies/',
      },
      {
        ...ReturnsAndReplacementsPage,
        path: '/returns-and-replacements/',
      },
      {
        ...ShippingAndDeliveryPage,
        path: '/shipping-and-delivery/',
      },
      {
        ...AboutUsPage,
        path: '/about-us/',
      },
      {
        ...WarrantiesPage,
        path: '/warranties/',
      },
      {
        ...ActivateYourWarrantyPage,
        path: '/activate-your-warranty/',
      },
      {
        ...RegisterYourProductPage,
        path: '/register-your-product/',
      },
      {
        ...TipsAndIdeas,
        path: '/tips-and-ideas/',
        exact: true,
      },
      {
        ...AcceptanceOfTermsOfUse,
        path: '/acceptance-of-terms-of-use',
      },
      {
        ...Blog,
        path: '/tips-and-ideas/:blogId',
      },
      {
        ...NotFoundPage,
      },
    ],
  },
];
