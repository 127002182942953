import React from 'react';

import styles from './styles.scss';

const ShippingAndDeliveryPage = () => {
  return (
    <div className={styles.ShippingAndDelivery}>
      <div className={styles.Title}>SHIPPING AND DELIVERY</div>
      <div className={styles.Content}>
        All orders transfered from our retailers will be proceeded within 24 hours and delivered to customers within 3-7
        days.
      </div>
    </div>
  );
};

export default {
  component: ShippingAndDeliveryPage,
};
