import React, { Component } from 'react';
import axios from 'axios';
import { isEmpty } from 'lodash';
import { HashLink } from 'react-router-hash-link';

import { BLOGS_API_ENDPOINT } from '@constants';

import { blogTransform } from '@utils/transformations/blog-transformation';

import styles from './styles.scss';

class TipsAndIdeas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blogs: [],
    };
  }

  componentDidMount() {
    axios.get(BLOGS_API_ENDPOINT).then(response => {
      this.setState({ blogs: response.data.map(blogTransform) });
    });
  }

  updateViewCount(blogId) {
    axios.post(`${BLOGS_API_ENDPOINT}update-views-count/`, { 'blog_id': blogId });
  }

  render() {
    const { blogs } = this.state;

    if (!isEmpty(blogs)) {
      return (
        <div className={styles.TipsAndIdeas}>
          {blogs.map(blog => {
            const textOnlyContent = blog.content.replace(/<p><img .*?><\/p>/g, '');
            const blogDetailUrl = `/tips-and-ideas/${blog.blogId}/`;
            const commentSectionUrl = `${blogDetailUrl}#comment`;
            return (
              <a
                key={`BLOG_${blog.title}`}
                className={styles.BlogItem}
                href={blogDetailUrl}
                onClick={() => this.updateViewCount(blog.blogId)}
              >
                <div className={styles.PreviewImageWrapper}>
                  <img className={styles.PreviewImage} src={blog.previewImage} />
                </div>
                <div className={styles.Summary}>
                  <img
                    src='/media/footer-logo.png'
                    alt='Vifah'
                    title='Vifah - Patio Savings'
                    className={styles.LogoImage}
                  />
                  <div className={styles.AuthorAndTimestamp}>
                    <div>{blog.author}</div>
                    <div>
                      {blog.createdAt} &#9679; {blog.estimation} {blog.estimation > 1 ? 'mins' : 'min'}
                    </div>
                  </div>
                  <div className={styles.Title}>{blog.title}</div>
                  <div class={styles.PreviewContent} dangerouslySetInnerHTML={{ __html: textOnlyContent }} />
                  <div className={styles.Divider} />
                  <span className={styles.Views}>
                    {blog.views} {blog.views > 1 ? 'views' : 'view'}
                  </span>
                  <HashLink to={commentSectionUrl} className={styles.AddComment}>
                    Write a comment
                  </HashLink>
                </div>
              </a>
            );
          })}
        </div>
      );
    } else {
      return null;
    }
  }
}

export default {
  component: TipsAndIdeas,
};
