import React from 'react';

import styles from './styles.scss';

const WarrantiesPage = () => {
  return (
    <div className={styles.Warranties}>
      <div className={styles.Title}>WARRANTIES</div>
      <div className={styles.Subtitle}>Policy Details</div>
      <div className={styles.Content}>
        <div className={styles.Item}>
          All manufacturer defects will be covered within one year once the product is delivered. VIFAH must be
          contacted for claim as soon as possible when issues are found, FID#, photos, Order infor must be provided.
          Then replacement/refund will be processed as requests.
        </div>
        <div className={styles.Item}>
          <b>Warranty covers the following cases of defect:</b>
          <p className={styles.Subitem}>
            Product shows defected as its arrival to customer when open box (not caused by shipping): missed or defected
            part(s), screw and hole are not matching each other, wrong part(s) from different product shipped together,
            defected of color during use within warranty period.
          </p>
          <p className={styles.Subitem}>
            Product shows defected as its arrival to customer when open box (caused by shipping): missed part(s) while
            packaging or shipping, wrong product or part(s) shipped, defect(s) caused by shipping.
          </p>
        </div>
        <div className={styles.Item}>
          <b>Warranty does not cover the following cases of defect:</b>
          <p className={styles.Subitem}>The weight put or lay on the product exceeded the provided weight capacity.</p>
          <p className={styles.Subitem}>The product wrongly assembled by not following the provided instruction.</p>
          <p className={styles.Subitem}>The defect(s) caused by Force Majeure while shipping or using product.</p>
          <p className={styles.Subitem}>Using any treatment product other then those suggested by VIFAH.</p>
          <p className={styles.Subitem}>Normal wear and tear occur during normal use.</p>
        </div>
      </div>
    </div>
  );
};

export default {
  component: WarrantiesPage,
};
