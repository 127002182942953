import React, { Component } from 'react';
import PropTypes from 'prop-types';

import styles from './styles.scss';

class Comment extends Component {
  render() {
    const { name, createdAt, body, isApproved } = this.props;
    if (isApproved) {
      return (
        <div className={styles.Comment}>
          <div className={styles.UserName}>{name}</div>
          <div className={styles.Timestamp}>{createdAt}</div>
          <div className={styles.Content}>{body}</div>
        </div>
      );
    } else {
      return null;
    }
  }
}

Comment.propTypes = {
  name: PropTypes.string,
  createdAt: PropTypes.string,
  body: PropTypes.string,
  isApproved: PropTypes.bool,
};

export default Comment;
