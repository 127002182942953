import React, { Component } from 'react';
import PropTypes from 'prop-types';

import styles from './styles.scss';

class Logo extends Component {
  render() {
    const { href, src, alt, title, customStyle } = this.props;
    return (
      <div className={styles.Logo}>
        <a href={href}>
          <img src={src} alt={alt} title={title} className={styles.LogoImage} style={customStyle} />
        </a>
      </div>
    );
  }
}

Logo.propTypes = {
  href: PropTypes.string,
  src: PropTypes.string,
  title: PropTypes.string,
  alt: PropTypes.string,
  customStyle: PropTypes.object,
};

Logo.defaultProps = {
  href: '',
  src: '',
  title: '',
  alt: '',
  customStyle: {},
};

export default Logo;
