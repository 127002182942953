import React, { Component } from 'react';

import styles from './styles.scss';

class SearchBar extends Component {
  render() {
    return (
      <div className={styles.SearchBarContainer}>
        <input className={styles.SearchBar} type='text' placeholder='V1381 .....'/>
        <button type='submit' className={styles.SearchButton}>
          <i className={'fa fa-search'} style={{ fontSize: '15px' }} />
        </button>
      </div>
    );
  }
}

export default SearchBar;
