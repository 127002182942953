// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".N7q-eOa0wprBf6t7pxeYKw\\=\\= {\n  padding: 150px 400px 75px 400px;\n  text-align: center;\n}\n.N7q-eOa0wprBf6t7pxeYKw\\=\\= .GHR1njGfN9mRNDjbSVBTfQ\\=\\= {\n  width: 180px;\n  height: 180px;\n  border-radius: 90px;\n  margin: 0 0 75px 0;\n}\n.N7q-eOa0wprBf6t7pxeYKw\\=\\= .uBLM0DJPfa70XoEDycjxnw\\=\\= {\n  font-size: 48px;\n  font-weight: 600;\n  margin-bottom: 40px;\n}\n.N7q-eOa0wprBf6t7pxeYKw\\=\\= .hkNMcWZRAxi4l1fCYKn43A\\=\\= {\n  font-size: 16px;\n}\n.N7q-eOa0wprBf6t7pxeYKw\\=\\= .hkNMcWZRAxi4l1fCYKn43A\\=\\= .KLcoYZHxvgyUsbydfbuwpg\\=\\= {\n  margin: 16px 0;\n}", "",{"version":3,"sources":["webpack://./source/components/returns-and-replacements-page/styles.scss"],"names":[],"mappings":"AAAA;EACI,+BAAA;EACA,kBAAA;AACJ;AACI;EACI,YAAA;EACA,aAAA;EACA,mBAAA;EACA,kBAAA;AACR;AAEI;EACI,eAAA;EACA,gBAAA;EACA,mBAAA;AAAR;AAGI;EACI,eAAA;AADR;AAGQ;EACI,cAAA;AADZ","sourcesContent":[".ReturnsAndReplacements {\n    padding: 150px 400px 75px 400px;\n    text-align: center;\n\n    .Image {\n        width: 180px;\n        height: 180px;\n        border-radius: 90px;\n        margin: 0 0 75px 0;\n    }\n\n    .Title {\n        font-size: 48px;\n        font-weight: 600;\n        margin-bottom: 40px;\n    }\n\n    .Content {\n        font-size: 16px;\n\n        .Item {\n            margin: 16px 0;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ReturnsAndReplacements": "N7q-eOa0wprBf6t7pxeYKw==",
	"Image": "GHR1njGfN9mRNDjbSVBTfQ==",
	"Title": "uBLM0DJPfa70XoEDycjxnw==",
	"Content": "hkNMcWZRAxi4l1fCYKn43A==",
	"Item": "KLcoYZHxvgyUsbydfbuwpg=="
};
export default ___CSS_LOADER_EXPORT___;
