import React from 'react';

import styles from './styles.scss';

const AboutUsPage = () => {
  return (
    <div className={styles.AboutUs}>
      <img className={styles.Image} src='/media/factory.jpg' />
      <div className={styles.Description}>
        <div className={styles.Title}>
          About Us
          <div className={styles.Divider} />
        </div>
        <div className={styles.Summary}>
          With 15-year experience in manufacturing and supplying outdoor furniture, VIFAH has established a reputation
          for manufacturing fine outdoor living products with excellent quality, style, and innovation at the right
          price. Over the years, VIFAH has quality manufacturing metal and wood products and as such, has become a
          leading manufacturer for some of the world’s most prestigious retailers and distributors in the US, Canada,
          Europe, Australia, South America, and Middle East. All stages of manufacturing are under strict control and
          quality check to prevent any defects delivered to consumers. VIFAH products save your budget, your space, your
          time, and turn your small patio into a charming hideaway. Our customer service team are always ready to assist
          you in any issue related to our products.
        </div>
      </div>
    </div>
  );
};

export default {
  component: AboutUsPage,
};
