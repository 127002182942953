import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { isEmpty } from 'lodash';

import { BLOGS_API_ENDPOINT } from '@constants';
import { blogDetailTransform } from '@utils/transformations/blog-detail-transformation';
import CommentForm from './comment-form';
import CommentList from './comment-list';
import styles from './styles.scss';

class Blog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blog: {},
    };
  }

  componentDidMount() {
    const blogId = this.props.match.params.blogId;
    axios.get(`${BLOGS_API_ENDPOINT}${blogId}/`).then(response => {
      this.setState({ blog: response.data.map(blogDetailTransform)[0] });
    });
  }

  render() {
    const { blog } = this.state;
    const blogId = this.props.match.params.blogId;

    if (!isEmpty(blog)) {
      return (
        <div className={styles.Blog}>
          <img src='/media/footer-logo.png' alt='Vifah' title='Vifah - Patio Savings' className={styles.LogoImage} />
          <div className={styles.Author}>
            {blog.author} &#9679; {blog.estimation} {blog.estimation > 1 ? 'mins' : 'min'} read
          </div>
          <div className={styles.Title}>{blog.title}</div>
          <div class={styles.Content} dangerouslySetInnerHTML={{ __html: blog.content }} />
          <CommentList comments={blog.comments} />
          <CommentForm blogId={blogId} />
        </div>
      );
    } else {
      return null;
    }
  }
}

Blog.propTypes = {
  match: PropTypes.object,
};

export default {
  component: Blog,
};
