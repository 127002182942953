import React from 'react';

import Logo from '@components/common/logo';
import styles from './styles.scss';

class Footer extends React.Component {
  render() {
    return (
      <div className={styles.Footer}>
        <div className='container'>
          <div className='row' style={{ paddingBottom: '50px' }}>
            <div className={styles.Item}>
              <Logo href='/' src='/media/footer-logo.png' alt='Vifah' title='Vifah - Patio Savings' />
            </div>
            <div className={styles.Item}>
              <ul>
                <li className={styles.Title}>HELP</li>
                <li>
                  <a href='/frequently-ask-questions/' title='Frequently Ask Questions'>
                    FAQS
                  </a>
                </li>
                <li>
                  <a href='/warranties/'>Warranty</a>
                </li>
                <li>
                  <a href='/shipping-and-delivery/'>Shipping & Delivery</a>
                </li>
                <li>
                  <a href='/returns-and-replacements/'>Returns & Replacements</a>
                </li>
              </ul>
            </div>
            <div className={styles.Item}>
              <ul>
                <li className={styles.Title}>EXPLORE</li>
                <li>
                  <a href='/about-us/'>About us</a>
                </li>
                <li>
                  <a href='/acceptance-of-terms-of-use'>Terms of use</a>
                </li>
                <li>
                  <a href='/our-policies/'>Our policy</a>
                </li>
                <li>
                  <a href='/'>Review</a>
                </li>
                <li>
                  <a href='/tips-and-ideas/'>Blog</a>
                </li>
              </ul>
            </div>
            <div className={styles.Item}>
              <ul>
                <li className={styles.Title}>CONTACT US</li>
                <li>
                  <a href='mailto:customerservice@vifah.com'>customerservice@vifah.com</a>
                </li>
              </ul>
            </div>
            <div className={styles.Item}>
              <ul>
                <li className={styles.img}>
                  <a>
                    <img className={styles.Icon} src='/media/ing-icon.png' title='' alt='' />
                  </a>
                  <a>
                    <img className={styles.Icon} src='/media/tw-icon.png' title='Twitter' alt='Twitter' />
                  </a>
                  <a href='mailto:customerservice@vifah.com'>
                    <img className={styles.Icon} src='/media/mail-icon.png' title='Mail' alt='Mail' />
                  </a>
                  <a href='https://www.facebook.com/VifahfurnitureUS'>
                    <img className={styles.Icon} src='/media/fb-icon.png' title='Facebook' alt='Facebook' />
                  </a>
                  <a href='https://www.youtube.com/channel/UCAGPMpobKW_XP5UVVE9nQPA'>
                    <img className={styles.Icon} src='/media/youtube-icon.png' title='Youtube' alt='Youtube' />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div style={{ textAlign: 'center', fontSize: '14px' }}>The only trusted website of Vifah brand.</div>
        <div style={{ textAlign: 'center', fontSize: '14px', fontWeight: '600' }}>
          Copyright 2020 &#169; Vifah - All rights reserved
        </div>
      </div>
    );
  }
}

export default Footer;
