import React, { Component } from 'react';
import axios from 'axios';
import { isEmpty } from 'lodash';

import { PRODUCTS_API_ENDPOINT } from '@constants';
import { productTransform } from '@utils/transformations/product-transformation';
import styles from './styles.scss';

class ProductsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
    };
  }

  componentDidMount() {
    axios.get(PRODUCTS_API_ENDPOINT).then(response => {
      this.setState({ products: response.data.map(productTransform) });
    });
  }

  render() {
    const { products } = this.state;
    if (!isEmpty(products)) {
      return (
        <div className={styles.Products}>
          {products.map(product => {
            const productLink = `/products/${product.skuCode.toLowerCase()}/`;
            return (
              <a key={`PRODUCTS_${product.productName}`} className={styles.Product} href={productLink}>
                <img
                  src={product.mainImage}
                  alt={product.productName}
                  title={product.productName}
                  className={styles.ProductImage}
                />
                <div className={styles.ProductName}>{product.productName}</div>
              </a>
            );
          })}
        </div>
      );
    } else {
      return null;
    }
  }
}

export default {
  component: ProductsPage,
};
