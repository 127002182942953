import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Comment from './comment';
import styles from './styles.scss';

class CommentList extends Component {
  render() {
    const { comments } = this.props;
    const numberOfComments = comments.filter(comment => comment.isApproved).length;
    return (
      <div className={styles.CommentList}>
        <div className={styles.Title}>
          {numberOfComments} {numberOfComments > 1 ? 'comments' : 'comment'}
        </div>
        {comments.map(comment => {
          return (
            <Comment
              key={`COMMENT_${comment.createdAt}`}
              name={comment.name}
              createdAt={comment.createdAt}
              body={comment.body}
              isApproved={comment.isApproved}
            />
          );
        })}
      </div>
    );
  }
}

CommentList.propTypes = {
  comments: PropTypes.array,
};

export default CommentList;
