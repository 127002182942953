// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dK\\+2X59GKeVAcP2URkAmxg\\=\\= {\n  padding: 25px;\n  max-width: 1200px;\n  margin: 0 auto;\n  overflow: hidden;\n  margin-top: 80px;\n}\n.dK\\+2X59GKeVAcP2URkAmxg\\=\\= .r\\+p99wB3CtDTUGn2moZurA\\=\\= {\n  font-size: 28px;\n  font-weight: 400;\n  padding: 5px 0;\n  display: block;\n  overflow: hidden;\n  margin-top: 15px;\n}\n.dK\\+2X59GKeVAcP2URkAmxg\\=\\= ._69ReQopq8VKBsAmIDyEsAA\\=\\= {\n  font-size: 18px;\n  line-height: 22px;\n  text-align: justify;\n  padding: 5px 0;\n}\n.dK\\+2X59GKeVAcP2URkAmxg\\=\\= ._69ReQopq8VKBsAmIDyEsAA\\=\\= ul {\n  list-style: disc;\n  margin: 10px 0 10px 20px;\n}\n.dK\\+2X59GKeVAcP2URkAmxg\\=\\= ._69ReQopq8VKBsAmIDyEsAA\\=\\= ul li {\n  padding-left: 10px;\n}", "",{"version":3,"sources":["webpack://./source/components/acceptance-of-terms-of-use/styles.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,iBAAA;EACA,cAAA;EACA,gBAAA;EACA,gBAAA;AACJ;AAAI;EACI,eAAA;EACA,gBAAA;EACA,cAAA;EACA,cAAA;EACA,gBAAA;EACA,gBAAA;AAER;AACI;EACI,eAAA;EACA,iBAAA;EACA,mBAAA;EACA,cAAA;AACR;AAAQ;EACI,gBAAA;EACA,wBAAA;AAEZ;AADY;EACI,kBAAA;AAGhB","sourcesContent":[".AcceptanceOfTermsOfUse {\n    padding: 25px;\n    max-width: 1200px;\n    margin: 0 auto;\n    overflow: hidden;\n    margin-top: 80px;\n    .Title {\n        font-size: 28px;\n        font-weight: 400;\n        padding: 5px 0;\n        display: block;\n        overflow: hidden;\n        margin-top: 15px;\n    }\n\n    .Summary {\n        font-size: 18px;\n        line-height: 22px;\n        text-align: justify;\n        padding: 5px 0;\n        ul {\n            list-style: disc;\n            margin: 10px 0 10px 20px;\n            li {\n                padding-left: 10px;\n            }\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AcceptanceOfTermsOfUse": "dK+2X59GKeVAcP2URkAmxg==",
	"Title": "r+p99wB3CtDTUGn2moZurA==",
	"Summary": "_69ReQopq8VKBsAmIDyEsAA=="
};
export default ___CSS_LOADER_EXPORT___;
