import React, { Component } from 'react';
import PropTypes from 'prop-types';

import styles from './styles.scss';

class DropdownMenu extends Component {
  renderDropdownMenu() {
    const { dropdownMenu } = this.props;
    return (
      <div className={styles.FirstLayerMenu}>
        <ul className={styles.MenuItem}>
          {Object.keys(dropdownMenu).map(key => {
            const firstLayerMenuItem = dropdownMenu[key];
            return (
              <a href={firstLayerMenuItem.href} key={`FirstLayerMenuItem_${firstLayerMenuItem.title}`}>
                <li className={styles.FirstLayerMenuItem}>
                  {firstLayerMenuItem.title}
                  <div className={styles.SecondLayerMenu}>
                    <ul>
                      {firstLayerMenuItem.items.map((secondLayerMenuItem, index) => {
                        return (
                          <li key={`SecondLayerMenuItem_${index}`} className={styles.SecondLayerMenuItem}>
                            {secondLayerMenuItem.title}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </li>
              </a>
            );
          })}
        </ul>
      </div>
    );
  }

  render() {
    const { isSubmenu, title, href } = this.props;
    return (
      <li className={styles.DropdownMenu}>
        <a href={href} title={title} className={styles.MenuItem}>
          <span className={styles.MenuItemTitle}>
            {title}
            <div className={styles.Divider} />
          </span>
        </a>
        {isSubmenu ? this.renderDropdownMenu() : null}
      </li>
    );
  }
}

DropdownMenu.propTypes = {
  isSubmenu: PropTypes.bool,
  dropdownMenu: PropTypes.object,
  title: PropTypes.string,
  href: PropTypes.string,
};

DropdownMenu.defaultProps = {
  isSubmenu: false,
  dropdownMenu: {},
  title: '',
  href: '',
};

export default DropdownMenu;
