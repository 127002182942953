import React from 'react';
import PropTypes from 'prop-types';
import { renderRoutes } from 'react-router-config';

import Layout from '@components/layout';

const App = ({ route }) => {
  return <Layout>{renderRoutes(route.routes)}</Layout>;
};

App.propTypes = {
  route: PropTypes.object,
};

export default {
  component: App,
};
