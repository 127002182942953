// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HNU1geIBaxes3rf-NBchRA\\=\\= {\n  padding: 150px 250px 450px 250px;\n  text-align: center;\n  background-color: #3A2315;\n}\n.HNU1geIBaxes3rf-NBchRA\\=\\= .VFC4Knehv0efgngRozwgrA\\=\\= {\n  font-size: 48px;\n  font-weight: 600;\n  margin-bottom: 40px;\n  color: white;\n}\n.HNU1geIBaxes3rf-NBchRA\\=\\= .uCYK97O\\+h-plkoG1O7oxDQ\\=\\= {\n  font-size: 20px;\n  color: white;\n}", "",{"version":3,"sources":["webpack://./source/components/shipping-and-delivery-page/styles.scss"],"names":[],"mappings":"AAAA;EACI,gCAAA;EACA,kBAAA;EACA,yBAAA;AACJ;AACI;EACI,eAAA;EACA,gBAAA;EACA,mBAAA;EACA,YAAA;AACR;AAEI;EACI,eAAA;EACA,YAAA;AAAR","sourcesContent":[".ShippingAndDelivery {\n    padding: 150px 250px 450px 250px;\n    text-align: center;\n    background-color: #3A2315;\n\n    .Title {\n        font-size: 48px;\n        font-weight: 600;\n        margin-bottom: 40px;\n        color: white\n    }\n\n    .Content {\n        font-size: 20px;\n        color: white;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ShippingAndDelivery": "HNU1geIBaxes3rf-NBchRA==",
	"Title": "VFC4Knehv0efgngRozwgrA==",
	"Content": "uCYK97O+h-plkoG1O7oxDQ=="
};
export default ___CSS_LOADER_EXPORT___;
