import React, { Component } from 'react';
import PropTypes from 'prop-types';

import NavigationBar from '@components/navigation-bar';
import Footer from '@components/layout/footer';
import styles from './styles.scss';

class Layout extends Component {
  render() {
    return (
      <div className={styles.Wrapper}>
        <NavigationBar />
        {this.props.children}
        <Footer />
      </div>
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node,
};

export default Layout;
