export const BASE_URL = 'https://vifah.com';
export const API_URL = 'https://api.vifah.com/api';
export const PRODUCTS_API_ENDPOINT = API_URL + '/v1/products/';
export const NEW_ARRIVALS_API_ENDPOINT = API_URL + '/v1/new-arrivals/';
export const FREQUENTLY_ASK_QUESTIONS_API_ENDPOINT = API_URL + '/v1/frequently-ask-questions/';
export const WARRANTIES_API_ENDPOINT = API_URL + '/api/v1/warranties/';
export const BLOGS_API_ENDPOINT = API_URL + '/v1/blogs/';
export const COMMENTS_API_ENDPOINT = API_URL + '/v1/comments/';

export const SITE_MAP = {
  explore: {
    title: 'EXPLORE',
    href: '',
    isSubmenu: true,
    active: false,
    path: '',
    submenu: {
      PartioFurniture: {
        title: 'Patio Furniture',
        href: '/products/',
        isSubmenu: true,
        active: false,
        path: '',
        items: [
          { to: '#', title: 'Patio Table', path: '' },
          { to: '#', title: 'Patio Chair', path: '' },
          { to: '#', title: 'Patio Chaise', path: '' },
          { to: '#', title: 'Patio Bench', path: '' },
          { to: '#', title: 'Patio Dining Set', path: '' },
          { to: '#', title: 'Patio Conversation Set', path: '' },
        ],
      },
      HomeDecor: {
        title: 'Home Decor',
        href: '#',
        isSubmenu: false,
        active: false,
        path: 'Explode',
        items: [],
      },
      NewArrivals: {
        title: 'New Arrivals',
        href: '/new-arrivals/',
        isSubmenu: false,
        active: false,
        path: '',
        items: [],
      },
    },
  },
  TipAndIdeas: {
    title: 'TIPS & IDEAS',
    href: '/tips-and-ideas/',
    path: '',
    isSubmenu: false,
    active: false,
    submenu: {},
  },
  ActivateWarranty: {
    title: 'ACTIVATE WARRANTY',
    href: '/activate-your-warranty/',
    path: '',
    isSubmenu: false,
    active: false,
    submenu: {},
  },
};
