// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".KzIrCHe\\+3vRV7Dkdf0LQFw\\=\\= .-WXM4Ek2NdQB-YVXY8dXTw\\=\\= {\n  display: inline-block;\n  padding: 25px 75px;\n}\n.KzIrCHe\\+3vRV7Dkdf0LQFw\\=\\= .-WXM4Ek2NdQB-YVXY8dXTw\\=\\= .cj4xWm8CjyVO\\+D82vTS36g\\=\\= {\n  width: 400px;\n  height: 400px;\n}\n.KzIrCHe\\+3vRV7Dkdf0LQFw\\=\\= .-WXM4Ek2NdQB-YVXY8dXTw\\=\\= ._1UHWatK85RbJsULN66HouA\\=\\= {\n  text-align: center;\n  font-size: 12px;\n  height: 50px;\n  width: 400px;\n  overflow: hidden;\n}", "",{"version":3,"sources":["webpack://./source/components/products-page/styles.scss"],"names":[],"mappings":"AACI;EACI,qBAAA;EACA,kBAAA;AAAR;AAEQ;EACI,YAAA;EACA,aAAA;AAAZ;AAGQ;EACI,kBAAA;EACA,eAAA;EACA,YAAA;EACA,YAAA;EACA,gBAAA;AADZ","sourcesContent":[".Products {\n    .Product {\n        display: inline-block;\n        padding: 25px 75px;\n\n        .ProductImage {\n            width: 400px;\n            height: 400px;\n        }\n    \n        .ProductName {\n            text-align: center;\n            font-size: 12px;\n            height: 50px;\n            width: 400px;\n            overflow: hidden; \n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Products": "KzIrCHe+3vRV7Dkdf0LQFw==",
	"Product": "-WXM4Ek2NdQB-YVXY8dXTw==",
	"ProductImage": "cj4xWm8CjyVO+D82vTS36g==",
	"ProductName": "_1UHWatK85RbJsULN66HouA=="
};
export default ___CSS_LOADER_EXPORT___;
