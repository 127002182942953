import React from 'react';

import Slide from '@components/Slide';

const Home = () => {
  return (
    <div className={'HomPage'}>
      <Slide />
      <div className={'sections'}>
        <a className={'about-us'} href='/about-us/'>
          <div className={'image'} style={{ backgroundImage: 'url(/media/factory.jpg)' }}>
            <img src='/media/factory.jpg' className={'onBackground'} title='Factory' alt='Factory' />
          </div>
          <div className={'descriptions'}>
            <h2 className={'title'}>About Us</h2>
            <div className={'line'}>
              <span />
            </div>
            <div className={'summary'}>
              With 15-year experience in manufacturing and supplying outdoor furniture, VIFAH has established a
              reputation for manufacturing fine outdoor living products with excellent quality, style, and innovation at
              the right price. Over the years, VIFAH has quality manufacturing metal and wood products and as such, has
              become a leading manufacturer for some of the world’s most prestigious retailers and distributors in the
              US, Canada, Europe, Australia, South America, and Middle East. All stages of manufacturing are under
              strict control and quality check to prevent any defects delivered to consumers. VIFAH products save your
              budget, your space, your time, and turn your small patio into a charming hideaway. Our customer service
              team are always ready to assist you in any issue related to our products.
            </div>
          </div>
        </a>
      </div>

      <div className={'section'}>
        <div className={'arrival'}>
          <div className={'title'}>New Arrivals</div>
          <div className={'list'}>
            <a className={'item'} href='/new-arrivals/'>
              <img
                src='/media/products/V1977_1.jpg' alt='' title='' />
            </a>
            <a className={'item'} href='/new-arrivals/'>
              <img src='/media/products/V1973SET1.jpg' alt='' title='' />
            </a>
            <a className={'item'} href='/new-arrivals/'>
              <img src='/media/products/V1975SET1_1.jpg' alt='' title='' />
            </a>
            <a className={'item'} href='/new-arrivals/'>
              <img src='/media/products/V1975SET2_2.jpg' alt='' title='' />
            </a>
          </div>
          <span className={'line'} />
        </div>
      </div>

      <div className={'section'}>
        <div className={'finduson'}>
          <div className={'title'}>Find us on</div>
          <div className={'list'}>
            <div className={'item'}>
              <a href='https://www.wayfair.com/' target='_blank'>
                <img src='/media/logo-wayfair.png' alt='Wayfair' title='Wayfair' />
              </a>
            </div>
            <div className={'item'}>
              <a href='https://www.lowes.com/' target='_blank'>
                <img src='/media/logo-lowes.png' alt="Lowe's" title="Lowe's" />
              </a>
            </div>
            <div className={'item'}>
              <a href='https://www.homedepot.com/' target='_blank'>
                <img src='/media/logo-home-depot.png' alt='The Home Depot' title='The Home Depot' />
              </a>
            </div>
            <div className={'item'}>
              <a href='https://www.overstock.com/' target='_blank'>
                <img src='/media/logo-overstock.png' alt='Overstock' title='Overstock' />
              </a>
            </div>
            <div className={'item'}>
              <img src='/media/logo-pier-1-imports.png' alt='Pier 1 Imports' title='Pier 1 Imports' />
            </div>
            <div className={'item'}>
              <a href='https://www.ashleyfurniture.com/' target='_blank'>
                <img src='/media/logo-ashley.png' alt='Ashley Furniture' title='Ashley Furniture' />
              </a>
            </div>
            <div className={'item'}>
              <a href='https://www.houzz.com/' target='_blank'>
                <img src='/media/logo-houzz.png' alt='Houzz' title='Houzz' />
              </a>
            </div>
            <div className={'item'}>
              <a href='https://www.target.com/' target='_blank'>
                <img src='/media/logo-target.png' alt='Target' title='Target' />
              </a>
            </div>
            <div className={'item'}>
              <a href='https://www.walmart.com/c/brand/vifah?facet=fulfillment_method%3AShipping' target='_blank'>
                <img src='/media/logo-walmart.png' alt='Walmart' title='Walmart' />
              </a>
            </div>
            <div className={'item'}>
              <a href='https://www.amazon.com/' target='_blank'>
                <img src='/media/logo-amazon.png' alt='Amazon' title='Amazon' />
              </a>
            </div>
            <div className={'item'}>
              <a href='https://www.bedbathandbeyond.com/' target='_blank'>
                <img src='/media/logo-bed-bath.png' alt='Bed Bath Beyond' title='Bed Bath Beyond' />
              </a>
            </div>
            <div className={'item'}>
              <a href='https://senior.com/collections/vifah' target='_blank'>
                <img src='/media/senior.com_logo_rgb_2020.png' alt='Senior' title='Senior' />
              </a>
            </div>
            <div className={'item'}>
              <a href='https://www.cymax.com/' target='_blank'>
                <img src='/media/logo-cymax.png' alt='Cymax' title='Cymax' />
              </a>
            </div>
            <div className={'item'}>
              <a href='https://www.zulily.com/' target='_blank'>
                <img src='/media/logo-zulily.png' alt='Zulily' title='Zulily' />
              </a>
            </div>
            <div className={'item'}>
              <a href='https://www.worldmarket.com/' target='_blank'>
                <img src='/media/logo-world-market.png' alt='World Market' title='World Market' />
              </a>
            </div>
            <div className={'item'}>
              <img src='/media/logo-hbc.png' alt='HBC' title='HBC' />
            </div>
            <div className={'item'}>
              <a href='https://www.totallyfurniture.com/' target='_blank'>
                <img src='/media/TF-logo-7.png' alt='Totally Furniture' title='Totally Furniture' />
              </a>
            </div>
          </div>
          <span className={'line'} />
        </div>
      </div>

      <div className={'sections'}>
        <div className={'about-us'}>
          <div className={'descriptions'}>
            <h2 className={'title'}>INSPIRATION</h2>
            <div className={'summary'} style={{ marginTop: '20px' }}>
              Don't know how to start a new patio building. <br />
              Check out our blog for useful tips and design ideas.
            </div>
            <div className={'button'}>
              Learn More <i className={'fas fa-arrow-right'} />
            </div>
          </div>
          <div className={'image'}>
            <img src='/media/inspiration.jpg' alt='inspiration' title='inspiration' />
          </div>
        </div>
      </div>

      <div className={'section'}>
        <div className={'customer'}>
          <div className={'title'}>WHAT OUR CUSTOMERS ARE SAYING</div>
          <div className={'list'}>
            <div className={'item'}>
              <div className={'name'}>Kimberly</div>
              <div className={'star'}>
                <i className={'fas fa-star'} />
                <i className={'fas fa-star'} />
                <i className={'fas fa-star'} />
                <i className={'fas fa-star'} />
              </div>
              <div className={'subject'}>Teenage son put this together without difficulty</div>
              <div className={'desc'}>
              Teenage son put this together without difficulty. It is more green in color than
              I anticipated which is why I gave it 4 stars.Seems sturdy, but as with all reviews,
              I've had this bench only about a month.
              </div>
            </div>
            <div className={'item'}>
              <div className={'name'}>Carol Hodgins</div>
              <div className={'star'}>
                <i className={'fas fa-star'} />
                <i className={'fas fa-star'} />
                <i className={'fas fa-star'} />
                <i className={'fas fa-star'} />
                <i className={'fas fa-star'} />
              </div>
              <div className={'subject'}>This has worked out fantastic</div>
              <div className={'desc'}>
              We just added this to our deck on our new house. This has worked out fantastic.
              We eventually got an umbrella to go with it. We love it
              </div>
            </div>
            <div className={'item'}>
              <div className={'name'}>Jessica</div>
              <div className={'star'}>
                <i className={'fas fa-star'} />
                <i className={'fas fa-star'} />
                <i className={'fas fa-star'} />
                <i className={'fas fa-star'} />
              </div>
              <div className={'subject'}>Perfect place to get your shoes on and start your day</div>
              <div className={'desc'}>
              I love this bench! We have it in our entry way and it is the perfect place
              to get your shoes on and start your day. Very sturdy and looks beautiful!
              </div>
            </div>
            <div className={'item'}>
              <div className={'name'}>Ashley</div>
              <div className={'star'}>
                <i className={'fas fa-star'} />
                <i className={'fas fa-star'} />
                <i className={'fas fa-star'} />
                <i className={'fas fa-star'} />
                <i className={'fas fa-star'} />
              </div>
              <div className={'subject'}>I love this bench</div>
              <div className={'desc'}>
              I love this bench! It was exactly what I had hoped it would be. I was dreading the assembly,
              but it was super easy! Just 6 screws with an allen wrench (included) and it was done.
              It's very sturdy and looks great on my front porch!
              </div>
            </div>
          </div>
          <div className='read-more-reviews-container'>
            <div className='read-more-reviews'>
              <span className='read-more-text'>Read more reviews</span>
              <i className={'fas fa-arrow-right'} />
            </div>
          </div>
          <span className={'line'} />
        </div>
      </div>
    </div>
  );
};

export default {
  component: Home,
};
