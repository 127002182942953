// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".T9gU49fP81HtOJNpesDi2w\\=\\= {\n  padding: 200px 400px 100px 400px;\n}\n.T9gU49fP81HtOJNpesDi2w\\=\\= .X9i93gFffxCXOZj\\+EZ1AXA\\=\\= {\n  font-size: 48px;\n  font-weight: 600;\n  margin-bottom: 40px;\n}\n.T9gU49fP81HtOJNpesDi2w\\=\\= .ye63ZwpT8TmUYUi\\+xiH3gQ\\=\\= {\n  font-size: 18px;\n  font-style: italic;\n  margin-bottom: 22px;\n}\n.T9gU49fP81HtOJNpesDi2w\\=\\= .jTyPsMPwTjxHFF1wFnQZnA\\=\\= {\n  font-size: 16px;\n}\n.T9gU49fP81HtOJNpesDi2w\\=\\= .jTyPsMPwTjxHFF1wFnQZnA\\=\\= .eo7f7UwPJbUIUEks2AHGLg\\=\\= {\n  margin: 16px 0;\n}", "",{"version":3,"sources":["webpack://./source/components/our-policies-page/styles.scss"],"names":[],"mappings":"AAAA;EACI,gCAAA;AACJ;AACI;EACI,eAAA;EACA,gBAAA;EACA,mBAAA;AACR;AAEI;EACI,eAAA;EACA,kBAAA;EACA,mBAAA;AAAR;AAGI;EACI,eAAA;AADR;AAGQ;EACI,cAAA;AADZ","sourcesContent":[".OurPolicies {\n    padding: 200px 400px 100px 400px;\n\n    .Title {\n        font-size: 48px;\n        font-weight: 600;\n        margin-bottom: 40px;\n    }\n\n    .Subtitle {\n        font-size: 18px;\n        font-style: italic;\n        margin-bottom: 22px;\n    }\n\n    .Content {\n        font-size: 16px;\n\n        .Policy {\n            margin: 16px 0;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"OurPolicies": "T9gU49fP81HtOJNpesDi2w==",
	"Title": "X9i93gFffxCXOZj+EZ1AXA==",
	"Subtitle": "ye63ZwpT8TmUYUi+xiH3gQ==",
	"Content": "jTyPsMPwTjxHFF1wFnQZnA==",
	"Policy": "eo7f7UwPJbUIUEks2AHGLg=="
};
export default ___CSS_LOADER_EXPORT___;
