import React from 'react';

import styles from './styles.scss';

const ReturnsAndReplacementsPage = () => {
  return (
    <div className={styles.ReturnsAndReplacements}>
      <img className={styles.Image} src='/media/returns-and-replacements-header.jpg' />
      <div className={styles.Title}>RETURN POLICY</div>
      <div className={styles.Content}>
        <div>
          VIFAH must be contacted for claim as soon as possible when issues are found, FID#, photos, Order information
          must be provided. Then replacement/refund will be processed as requests.
        </div>
        <div className={styles.Item}>
          <b>Replacement:</b>
          <div>Must be contacted the store where the order is placed.</div>
          <div>
            Seller will provide replacement of new part(s) or new product in case of defect(s) covered by warranty term.
            Fees related to ship new part(s) or product shall be charge to seller.
          </div>
        </div>
        <div className={styles.Item}>
          <b>Return:</b>

          <div>Must be contacted the store where the order is placed</div>
          <div>Order information must be provided.</div>
          <div>
            In case, product is returned by buyer remorse, 30% of restocking fee is applied, RA# will need to be
            provided when contact customerservice@vifah.com to request the return.
          </div>
          <div>Customer is responsible for re-shipping fee.</div>
          <div>Return item will be inspected after receiving.</div>
        </div>
      </div>
    </div>
  );
};

export default {
  component: ReturnsAndReplacementsPage,
};
