import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import { COMMENTS_API_ENDPOINT } from '@constants';
import styles from './styles.scss';

class CommentForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      body: '',
    };
    this.handleCustomerInformationChange = this.handleCustomerInformationChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleCustomerInformationChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();
    const { name, email, body } = this.state;
    const { blogId } = this.props;
    const comment = {
      name: name,
      email: email,
      body: body,
      'blog_id': blogId,
    };
    axios.post(COMMENTS_API_ENDPOINT, comment);
    document.getElementById('comment-form').reset();
  }

  render() {
    return (
      <div className={styles.CommentForm}>
        <div className={styles.Title} id='comment'>Leave a comment</div>
        <textarea
          name='body'
          form='comment-form'
          className={styles.Comment}
          placeholder='Comment*'
          onChange={this.handleCustomerInformationChange}
          required
        />
        <form id='comment-form' className={styles.FormWrapper} onSubmit={this.handleSubmit}>
          <div>
            <input
              type='text'
              name='name'
              placeholder='Name*'
              className={styles.ShortInput}
              onChange={this.handleCustomerInformationChange}
              autoComplete='off'
              required
            />
            <input
              type='email'
              name='email'
              placeholder='Email*'
              className={styles.ShortInput}
              onChange={this.handleCustomerInformationChange}
              autoComplete='off'
              required
            />
          </div>
          <div>
            <button className={styles.SubmitButton}>Add Comment</button>
          </div>
        </form>
      </div>
    );
  }
}

CommentForm.propTypes = {
  blogId: PropTypes.string,
};

export default CommentForm;
