import React, { Component } from 'react';

import styles from './styles.scss';

class Slide extends Component {
  render() {
    return (
      <div className={styles.TopSlide}>
        <img src='media/headers.jpg' alt='Vifah' title='Vifah' />
        <div className={styles.Text}>
          <div className={styles.Title}>Saving Space</div>
          <a className={styles.Button} href='/all-products/'>
            EXPLORE <i className={'fas fa-arrow-right'} />
          </a>
        </div>
      </div>
    );
  }
}

export default Slide;
