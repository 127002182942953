import React, { Component } from 'react';
import axios from 'axios';
import { isEmpty } from 'lodash';

import { NEW_ARRIVALS_API_ENDPOINT } from '@constants';
import { productTransform } from '@utils/transformations/product-transformation';
import styles from './styles.scss';

class NewArrivalsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newArrivals: [],
    };
  }

  componentDidMount() {
    axios.get(NEW_ARRIVALS_API_ENDPOINT).then(response => {
      this.setState({ newArrivals: response.data.map(productTransform) });
    });
  }

  render() {
    const { newArrivals } = this.state;
    if (!isEmpty(newArrivals)) {
      return (
        <div className={styles.NewArrivals}>
          {newArrivals.map(newArrival => {
            const productLink = `/new-arrivals/${newArrival.skuCode.toLowerCase()}/`;
            return (
              <a key={`NEW_ARRIVALS_${newArrival.productName}`} className={styles.Product} href={productLink}>
                <img
                  src={newArrival.mainImage}
                  alt={newArrival.productName}
                  title={newArrival.productName}
                  className={styles.ProductImage}
                />
                <div className={styles.ProductName}>{newArrival.productName}</div>
              </a>
            );
          })}
        </div>
      );
    } else {
      return null;
    }
  }
}

export default {
  component: NewArrivalsPage,
};
