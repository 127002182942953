// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".XhMIQSCgzHpnj-8TVl3TbA\\=\\= {\n  padding: 200px 400px 100px 400px;\n}\n.XhMIQSCgzHpnj-8TVl3TbA\\=\\= .UfFgJBLmf4zhRekjswTs9g\\=\\= {\n  font-size: 48px;\n  font-weight: 600;\n  margin-bottom: 40px;\n}\n.XhMIQSCgzHpnj-8TVl3TbA\\=\\= .K0iFkS6VpGibgl5LPdAQ0A\\=\\= {\n  font-size: 18px;\n  font-style: italic;\n  margin-bottom: 22px;\n}\n.XhMIQSCgzHpnj-8TVl3TbA\\=\\= .VpoCoodeIMSZECH-yN-2Ww\\=\\= {\n  font-size: 16px;\n}\n.XhMIQSCgzHpnj-8TVl3TbA\\=\\= .VpoCoodeIMSZECH-yN-2Ww\\=\\= ._3M-FV2ZpnIyBdJKpIEaBSA\\=\\= {\n  margin: 16px 0;\n}\n.XhMIQSCgzHpnj-8TVl3TbA\\=\\= .VpoCoodeIMSZECH-yN-2Ww\\=\\= ._3M-FV2ZpnIyBdJKpIEaBSA\\=\\= ._9yP11XPKMcufESLKvASoTQ\\=\\= {\n  margin-bottom: 15px;\n}", "",{"version":3,"sources":["webpack://./source/components/warranties-page/styles.scss"],"names":[],"mappings":"AAAA;EACI,gCAAA;AACJ;AACI;EACI,eAAA;EACA,gBAAA;EACA,mBAAA;AACR;AAEI;EACI,eAAA;EACA,kBAAA;EACA,mBAAA;AAAR;AAGI;EACI,eAAA;AADR;AAGQ;EACI,cAAA;AADZ;AAGY;EACI,mBAAA;AADhB","sourcesContent":[".Warranties {\n    padding: 200px 400px 100px 400px;\n\n    .Title {\n        font-size: 48px;\n        font-weight: 600;\n        margin-bottom: 40px;\n    }\n\n    .Subtitle {\n        font-size: 18px;\n        font-style: italic;\n        margin-bottom: 22px;\n    }\n\n    .Content {\n        font-size: 16px;\n\n        .Item {\n            margin: 16px 0;\n\n            .Subitem {\n                margin-bottom: 15px;\n            }\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Warranties": "XhMIQSCgzHpnj-8TVl3TbA==",
	"Title": "UfFgJBLmf4zhRekjswTs9g==",
	"Subtitle": "K0iFkS6VpGibgl5LPdAQ0A==",
	"Content": "VpoCoodeIMSZECH-yN-2Ww==",
	"Item": "_3M-FV2ZpnIyBdJKpIEaBSA==",
	"Subitem": "_9yP11XPKMcufESLKvASoTQ=="
};
export default ___CSS_LOADER_EXPORT___;
