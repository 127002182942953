import React, { Component } from 'react';

import { SITE_MAP } from '@constants';
import DropdownMenu from '@components/navigation-bar/dropdown-menu';
import Logo from '@components/common/logo';
import SearchBar from '@components/navigation-bar/search-bar';
import styles from './styles.scss';

class NavigationBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowmenu: false,
    };
    this.onToggleButton = this.onToggleButton.bind(this);
  }

  onToggleButton() {
    const isShowmenu = this.state.isShowmenu;
    this.setState({
      isShowmenu: !isShowmenu,
    });
  }

  render() {
    return (
      <div className={styles.HeaderTop}>
        <div className='container'>
          <div className={`${styles.HeaderTopContent} ${this.state.isShowmenu ? styles.Show : ''}`}>
            <Logo
              href='/'
              src='/media/logo.png'
              alt='Vifah'
              title='Vifah - Patio Savings'
              customStyle={{ marginTop: '15px' }}
            />
            <div className={styles.MainMenu}>
              <ul>
                {Object.keys(SITE_MAP).map((key, index) => {
                  const item = SITE_MAP[key];
                  return (
                    <DropdownMenu
                      isSubmenu={item.isSubmenu}
                      dropdownMenu={item.submenu}
                      title={item.title}
                      href={item.href}
                      key={`MenuItem_${index}`}
                    />
                  );
                })}
                <SearchBar />
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NavigationBar;
