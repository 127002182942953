import React from 'react';

import styles from './styles.scss';

const ActivateYourWarrantyPage = () => {
  return (
    <div className={styles.ActivateYourWarranty}>
      <div className={styles.headers}>WARRANTY, REPLACEMENT AND RETURN POLICY</div>
      <div className={styles.content}>
        <h3 className={styles.title}>VIFAH Furniture Limited Warranties</h3>
        <div className={styles.desc}>
        VIFAH, subject to all the limitations and exclusions described in these Limited Warranties,
        warrants the following VIFAH products and parts against material manufacturing defects for
        the duration set forth next to that product or part in the table below.
          <table cellPadding={0} cellSpacing={0}>
            <tr>
              <th>Product or Part</th>
              <th>Warranty Duration</th>
              <th>Warranty Term</th>
            </tr>
            <tr>
              <td>Cushioning (*)</td>
              <td>6 Month Limited Warranty</td>
              <td>Replacement, no refund</td>
            </tr>
            <tr>
              <td>Leather / Faux Leather and Upholstery Fabric (**)</td>
              <td>6 Month Limited Warranty</td>
              <td>Replacement, no refund</td>
            </tr>
            <tr>
              <td>Wicker Furniture</td>
              <td>1 Year Limited Warranty</td>
              <td>Replacement, no refund</td>
            </tr>
            <tr>
              <td>Outdoor Furniture - Outer Finishes</td>
              <td>6 Month Limited Warranty</td>
              <td>Replacement, no refund</td>
            </tr>
            <tr>
              <td>Indoor Furniture - Outer Finishes</td>
              <td>1 Year Limited Warranty</td>
              <td>Replacement, no refund</td>
            </tr>
            <tr>
              <td>Outdoor Furniture - Steel Frames</td>
              <td>1 Year Limited Warranty</td>
              <td>Refund (***)</td>
            </tr>
            <tr>
              <td>Indoor Furniture - Steel Frames</td>
              <td>1 Year Limited Warranty</td>
              <td>Refund (***)</td>
            </tr>
            <tr>
              <td>Furniture - Wood Frames</td>
              <td>1 Year Limited Warranty</td>
              <td>Refund (***)</td>
            </tr>
            <tr>
              <td>Office Furniture – Steel Frame & Motor</td>
              <td>3 Year Limited Warranty</td>
              <td>Replacement or Refund (***)</td>
            </tr>
            <tr>
              <td>Office Furniture – Wood Table Top</td>
              <td>1 Year Limited Warranty</td>
              <td>Replacement or Refund (***)</td>
            </tr>
          </table>
          <h3 className={styles.title}>Warranty covers the following cases of defect:</h3>
          <ul>
            <li>
              <label>-</label>
              <div className={styles.value}>
              Product shows defected as its arrival to customer when open box (not caused by shipping):
              missed or defective part(s), screw and hole are not matching each other, wrong part(s)
              from different product shipped together
              </div>
            </li>
            <li>
              <label>-</label>
              <div className={styles.value}>
                Product shows defective by manufacturer while using within the warranty duration
              </div>
            </li>
          </ul>

          <h3 className={styles.title}>Warranty does not cover the following cases of defect:</h3>
          <ul>
            <li>
              <label>-</label>
              <div className={styles.value}>
                The weight put or lay on the product exceeded the provided weight capacity
              </div>
            </li>
            <li>
              <label>-</label>
              <div className={styles.value}>
                The product wrongly assembled by not following the provided instruction or misuse
              </div>
            </li>
            <li>
              <label>-</label>
              <div className={styles.value}>Wear and Tear</div>
            </li>
            <li>
              <label>-</label>
              <div className={styles.value}>Abuse and neglect</div>
            </li>
            <li>
              <label>-</label>
              <div className={styles.value}>Physical damage from pets or by accidents</div>
            </li>
            <li>
              <label>-</label>
              <div className={styles.value}>Odors</div>
            </li>
            <li>
              <label>-</label>
              <div className={styles.value}>Cracking and peeling of bonded and faux leather</div>
            </li>
            <li><label>-</label>
              <div className={styles.value}>Commercial Use</div></li>
            <li>
              <label>-</label>
              <div className={styles.value}>Self-Modification</div>
            </li>
          </ul>
        </div>

        <h3 className={styles.title}>Replacement</h3>
        <div className={styles.desc}>
          Damaged/Defected products are disposal. Photos of the damages required.
          Seller will provide replacement of new part(s) or new product in case of
          defect(s) covered by warranty term. Fees related to ship new part(s)
          or product shall be charged to the seller.
        </div>

        <h3 className={styles.title}>Return:</h3>
        <div className={styles.desc}>
          In any case, if the customer is not satisfied with the product showing
          no defect or not being in any unusable state, and prefers to return the
          shipped product back, the customer shall notify the seller before sending the product back. <br /><br />
          Product needs to be originally packed when returned
          Shipping fee and restocking fee might be required by the reseller
        </div>

        <h3 className={styles.title}>Refund:</h3>
        <div className={styles.desc}>
          In any cases product show defects covered in warranty term and user does not prefer replacement
          of any kind, they may contact customer service department via email: customerservice@vifah.com

          <ul>
            <li>
              <label>-</label>
              <div className={styles.value}>
                All VIFAH warranties are limited warranties and are limited to the original
                purchaser with proof of purchase.
              </div>
            </li>
            <li>
              <label>-</label>
              <div className={styles.value}>
                In some cases, everyday wear and tear may depreciate for the time that the product was in use.
              </div>
            </li>
            <li>
              <label>-</label>
              <div className={styles.value}>
                All shipments should be inspected immediately upon arrival.
                Please make note of any damage to boxes when signing for shipment.
              </div>
            </li>
            <li>
              <label>-</label>
              <div className={styles.value}>
                Report any losses or damages promptly to VIFAH.
              </div>
            </li>
            <li>
              <label>-</label>
              <div className={styles.value}>Notification must be made within one week of delivery.</div>
            </li>
            <li>
              <label>-</label>
              <div className={styles.value}>
                <strong>Save damaged cartons until advised by the VIFAH or claim is resolved</strong>
              </div>
            </li>
            <li>
              <label>-</label>
              <div className={styles.value}>
                All returns MUST be authorized by VIFAH, which will advise you as to return
                authorization and arrange for pickup or repair of any damaged merchandise
              </div>
            </li>
          </ul>

          <ul style={{ marginTop: '40px' }}>
            <li>
              <label>(*)</label>
              <div className={styles.value}>
                All seat cushion cores in both Unattached Cushioning and Attached Cushioning
                will soften with normal use and will conform to the shape of the user.
                This shall be considered normal wear, and shall not be considered a
                loss of resiliency or a material manufacturing defect.
              </div>
            </li>

            <li>
              <label>(**)</label>
              <div className={styles.value}>
                The Leather  / Faux Leather and Upholstery Fabric Limited Warranty also includes seam slippage,
                cracking, and dye transfers. Some variations of shade and tone are to be expected as are the nicks,
                scratches, and wrinkles, these characteristics are in no way to be considered a defect. <br />

                These Limited Warranties do not cover tears, flattening of nap, pilling, fading, or
                shrinking and is not valid when heavy soiling or abuse is evident. <br />

                These Limited Warranties also do not cover protective finishes, velvets,
                and velvet types, since by their very nature, these fabrics are of soft texture and
                will crush, shade and mark more readily than most fabrics; these characteristics
                are in no way to be considered a defect.
              </div>
            </li>

            <li>
              <label>(***)</label>
              <div className={styles.value}>
                Refund: please be noticed, all refunds will be processed by VIFAH’s reseller – from
                whom the customer made their order. VIFAH only works with their re-sellers on financial issues.
              </div>
            </li>
          </ul>
        </div>

      </div>
    </div>
  );
};

export default {
  component: ActivateYourWarrantyPage,
};
