// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".B4ntTjGzNDFpzWoJX\\+CF2Q\\=\\= {\n  margin-bottom: 25px;\n  border-bottom: 1px solid #767676;\n  padding-bottom: 10px;\n  padding-left: 20px;\n}\n.B4ntTjGzNDFpzWoJX\\+CF2Q\\=\\=:last-child {\n  margin-bottom: 0;\n  border-bottom: none;\n  padding-bottom: 0;\n}\n.B4ntTjGzNDFpzWoJX\\+CF2Q\\=\\= .gicHGdUO5Lerg9WqKJ2-HA\\=\\= {\n  font-size: 26px;\n  font-weight: 600;\n  color: #3A2315;\n}\n.B4ntTjGzNDFpzWoJX\\+CF2Q\\=\\= .AIDFt110UWfGYTSl1wtJQA\\=\\= {\n  margin: 5px 0 10px 0;\n  font-size: 14px;\n  color: #767676;\n}\n.B4ntTjGzNDFpzWoJX\\+CF2Q\\=\\= ._2-JKH\\+OuEUxZGApf9qfYCw\\=\\= {\n  font-size: 18px;\n  color: black;\n}", "",{"version":3,"sources":["webpack://./source/components/blog/comment-list/comment/styles.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,gCAAA;EACA,oBAAA;EACA,kBAAA;AACF;AACE;EACE,gBAAA;EACA,mBAAA;EACA,iBAAA;AACJ;AAEE;EACE,eAAA;EACA,gBAAA;EACA,cAAA;AAAJ;AAGE;EACE,oBAAA;EACA,eAAA;EACA,cAAA;AADJ;AAIE;EACE,eAAA;EACA,YAAA;AAFJ","sourcesContent":[".Comment {\n  margin-bottom: 25px;\n  border-bottom: 1px solid #767676;\n  padding-bottom: 10px;\n  padding-left: 20px;\n\n  &:last-child {\n    margin-bottom: 0;\n    border-bottom: none;\n    padding-bottom: 0;\n  }\n\n  .UserName {\n    font-size: 26px;\n    font-weight: 600;\n    color: #3A2315;\n  }\n\n  .Timestamp {\n    margin: 5px 0 10px 0;\n    font-size: 14px;\n    color: #767676;\n  }\n\n  .Content {\n    font-size: 18px;\n    color: black;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Comment": "B4ntTjGzNDFpzWoJX+CF2Q==",
	"UserName": "gicHGdUO5Lerg9WqKJ2-HA==",
	"Timestamp": "AIDFt110UWfGYTSl1wtJQA==",
	"Content": "_2-JKH+OuEUxZGApf9qfYCw=="
};
export default ___CSS_LOADER_EXPORT___;
