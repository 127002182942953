import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { isEmpty } from 'lodash';

import ProductDetail from '@components/common/product-detail';
import { productDetailTransform } from '@utils/transformations/product-detail-transformation';
import { PRODUCTS_API_ENDPOINT } from '@constants';

class ProductDetailPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productDetail: {},
    };
  }

  componentDidMount() {
    const productId = this.props.match.params.skuCode.toUpperCase();
    axios.get(`${PRODUCTS_API_ENDPOINT}${productId}/`).then(response => {
      this.setState({ productDetail: response.data.map(productDetailTransform)[0] });
    });
  }

  render() {
    const { productDetail } = this.state;
    if (!isEmpty(productDetail)) {
      return (
        <ProductDetail
          images={productDetail.images}
          description={productDetail.description}
          productName={productDetail.productName}
          skuCode={productDetail.skuCode}
          feature={productDetail.feature}
        />
      );
    } else {
      return null;
    }
  }
}

ProductDetailPage.propTypes = {
  match: PropTypes.object,
};

export default {
  component: ProductDetailPage,
};
