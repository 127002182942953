// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._0I60xQV2I-boLHU8W8eAlQ\\=\\= {\n  position: relative;\n  height: 80px;\n  width: 200px;\n  left: 100px;\n}\n._0I60xQV2I-boLHU8W8eAlQ\\=\\= .\\+XHma3R26BlJjRwYD-3e0A\\=\\= {\n  border: 1px #3C2415 solid;\n  height: 26px;\n  border-radius: 25px;\n  padding: 0px 15px;\n  font-size: 14px;\n  color: #3C2415;\n  margin-top: 27px;\n  width: 100%;\n}\n._0I60xQV2I-boLHU8W8eAlQ\\=\\= .\\+XHma3R26BlJjRwYD-3e0A\\=\\=:focus {\n  outline: none;\n}\n._0I60xQV2I-boLHU8W8eAlQ\\=\\= .\\--u4t9-DAfX-Qf0rrHO9rg\\=\\= {\n  position: absolute;\n  bottom: 32px;\n  right: 10px;\n}\n\n@media (max-width: 676px) {\n  ._0I60xQV2I-boLHU8W8eAlQ\\=\\= {\n    position: relative;\n    height: 80px;\n    width: 120px;\n  }\n}", "",{"version":3,"sources":["webpack://./source/components/navigation-bar/search-bar/styles.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,YAAA;EACA,YAAA;EACA,WAAA;AACJ;AACI;EACI,yBAAA;EACA,YAAA;EACA,mBAAA;EACA,iBAAA;EACA,eAAA;EACA,cAAA;EACA,gBAAA;EACA,WAAA;AACR;AACQ;EACI,aAAA;AACZ;AAGI;EACI,kBAAA;EACA,YAAA;EACA,WAAA;AADR;;AAKA;EACI;IACI,kBAAA;IACA,YAAA;IACA,YAAA;EAFN;AACF","sourcesContent":[".SearchBarContainer {\n    position: relative;\n    height: 80px;\n    width: 200px;\n    left: 100px;\n\n    .SearchBar {\n        border: 1px #3C2415 solid;\n        height: 26px;\n        border-radius: 25px;\n        padding: 0px 15px;\n        font-size: 14px;\n        color: #3C2415;\n        margin-top: 27px;\n        width: 100%;\n\n        &:focus {\n            outline: none;\n        }\n    }\n\n    .SearchButton {\n        position: absolute;\n        bottom: 32px;\n        right: 10px;\n    }\n}\n\n@media(max-width: 676px) {\n    .SearchBarContainer {\n        position: relative;\n        height: 80px;\n        width: 120px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SearchBarContainer": "_0I60xQV2I-boLHU8W8eAlQ==",
	"SearchBar": "+XHma3R26BlJjRwYD-3e0A==",
	"SearchButton": "--u4t9-DAfX-Qf0rrHO9rg=="
};
export default ___CSS_LOADER_EXPORT___;
