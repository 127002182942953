import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactImageZoom from 'react-image-zoom';

import styles from './styles.scss';

class ProductDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mainImage: props.images.filter(image => image.imageType === 'MAIN_IMAGE')[0],
      width: 0,
      height: 0,
    };
    this.handleClickOnSmallImage = this.handleClickOnSmallImage.bind(this);
  }

  handleClickOnSmallImage(event) {
    const { images } = this.props;
    const currentImage = images.filter(image => image.imageSource === event.target.src)[0];
    this.setState({ mainImage: currentImage });
  }

  render() {
    const { images, description, productName, skuCode, feature } = this.props;
    const { mainImage } = this.state;
    const imageZoomProps = {
      width: 500,
      height: 400,
      zoomWidth: 500,
      zoomPosition: 'right',
      img: mainImage.imageSource,
      offset: {
        vertical: 0,
        horizontal: 20,
      },
      scale: 1,
    };
    return (
      <div className={styles.ProductDetail}>
        <div className={styles.ImageAndDescription}>
          <ReactImageZoom {...imageZoomProps} className={styles.MainImage} />
          <div className={styles.SmallImagesWrapper}>
            {images.map(image => {
              return (
                <img
                  key={`IMAGE_${image}`}
                  src={image.imageSource}
                  className={styles.SmallImage}
                  onClick={this.handleClickOnSmallImage}
                />
              );
            })}
          </div>
          <div className={styles.Description}>{description}</div>
        </div>
        <div className={styles.ProductNameAndFeature}>
          <div className={styles.ProductName}>{productName}</div>
          <div className={styles.SKUCode}>{`SKU: ${skuCode}`}</div>
          <div className={styles.Feature}>
            <div className={styles.Title}>Feature</div>
            <div>{feature}</div>
          </div>
        </div>
      </div>
    );
  }
}

ProductDetail.propTypes = {
  images: PropTypes.array,
  description: PropTypes.string,
  productName: PropTypes.string,
  skuCode: PropTypes.string,
  feature: PropTypes.object,
};

ProductDetail.defaultProps = {
  images: [],
  description: '',
  productName: '',
  skuCode: '',
  feature: {},
};

export default ProductDetail;
